export function formatOrganizationNo(orgNo: string | undefined | null) {
  if (!orgNo) return "";
  const number = orgNo.replace(/[^\d]/g, "");
  return number.slice(0, -4) + "-" + number.slice(-4);
}

export function formatSsn(ssn: string | undefined | null) {
  if (!ssn) return "";
  return ssn.replace(/^(\d{8})(\d{4})$/, "$1-$2");
}

export function formatMemory(memory: string): string {
  const memArray = memory.split(/(\d+)/);
  return `${memArray[1]} ${memArray[2]}`;
}
