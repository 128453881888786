export function curry(fn, arity: number = fn.length) {
  return (function curried(prevArgs: any[]) {
    return function nextCurried(...nextArgs) {
      const args = [...prevArgs, ...nextArgs];
      if (args.length >= arity) {
        return fn(...args);
      }
      return curried(args);
    };
  })([]);
}

function getPropFromObj<T, U extends keyof T>(prop: U, obj: T) {
  return obj?.[prop];
}

export const compose = (...fnx) => fnx.reduceRight((f, g) => (...args) => g(f(...args)));
export const pick = curry(getPropFromObj);

export const valueOrStringZero = (x: string | undefined | null) => x ?? "0";

export const getFeeAndParseToNumber = (key: string): ((obj: any) => number) =>
  compose(parseFloat, valueOrStringZero, pick(key));

export const safeParseToNumber = compose(parseFloat, valueOrStringZero);
