import Big from "big.js";
import {
  AnyBasketLineType,
  isSubscriptionLine,
  isSwitchboardLineType,
  LineProductItem,
  isAccessoryProduct,
  isHardwareProduct,
  isSubscriptionProduct,
  Line,
  Basket,
  LineType,
  isEmnLineType,
} from "../../typings/types";
import { Ga4Product } from "@telia/b2b-ecommerce-tracking";
import {
  ACCESSORY_LISTNAME,
  EMN_LISTNAME,
  HARDWARE_LISTNAME,
  SUBSCRIPTION_LISTNAME,
} from "./ga4constants";

export function totalProductPrice(products: Ga4Product[]): number {
  return products
    .map((product) => product.price)
    .reduce((acc, current) => Big(acc).add(Big(current)), Big(0))
    .toNumber();
}

export function productPrice(
  product: {
    oneTimeFee: string;
    recurringFee: string;
    commitment?: number;
  },
  quantity: number
): number {
  const reccuringFeeForCommitment = Big(product.recurringFee)
    .mul(quantity)
    .mul(product.commitment ?? 1);
  return Number(Big(product.oneTimeFee).mul(quantity).add(reccuringFeeForCommitment).toFixed(2));
}

export function buildLinePayload(
  line: AnyBasketLineType<Line>,
  boughtWithHardware: boolean = false
): Ga4Product[] {
  const quantity = isEmnLineType(line) ? line.quantity : 1;

  const products = [line.product];
  if (isSubscriptionLine(line)) {
    products.push(...line.addons, ...line.datasims);
    if (line.mds) {
      products.push(line.mds);
    }
  } else if (isSwitchboardLineType(line)) {
    products.push(...line.addons);
  }
  return products.map((product) =>
    buildProductPayload(product, line.lineType, boughtWithHardware, quantity)
  );
}

export function buildProductPayload(
  product: LineProductItem,
  lineType: LineType,
  boughtWithHardware: boolean = false,
  quantity: number
): Ga4Product {
  let brand = "Telia";
  if (isAccessoryProduct(product) || isHardwareProduct(product)) {
    brand = product.name.replace(/ .*/, ""); // TODO get brand from backend, now assume first word is the brand
  }

  const itemListName = (): Ga4Product["itemListName"] => {
    switch (lineType) {
      case LineType.SUBSCRIPTION:
      case LineType.SWITCHBOARD:
      case LineType.PROCESS:
        return boughtWithHardware ? HARDWARE_LISTNAME : SUBSCRIPTION_LISTNAME;
        break;
      case LineType.ACCESSORY:
        return ACCESSORY_LISTNAME;
      case LineType.HARDWARE:
        return HARDWARE_LISTNAME;
      case LineType.EMN:
        return EMN_LISTNAME;
    }
  };

  return {
    brand,
    name: product.name,
    id: product.productCode,
    category: product.category,
    subCategory: product.subCategory,
    itemListName: itemListName(),
    quantity: quantity,
    onetimePrice: Number(product.oneTimeFee),
    recurringPrice: Number(product.recurringFee),
    newOrExtend: isSubscriptionProduct(product) ? "extension" : "new",
    couponCode: "n/a",
    price: productPrice(product, quantity),
    variant: isHardwareProduct(product) ? `${product.memory} ${product.color}` : undefined,
  };
}

export function getAllBasketLines(basket: Basket): AnyBasketLineType<Line>[] {
  return [
    ...basket.lines.map((line) => ({ ...line, lineType: LineType.SUBSCRIPTION })),
    ...basket.hardwareLines.map((line) => ({ ...line, lineType: LineType.HARDWARE })),
    ...basket.accessoryLines.map((line) => ({ ...line, lineType: LineType.ACCESSORY })),
    ...basket.processLines.map((line) => ({ ...line, lineType: LineType.PROCESS })),
    ...basket.switchboardUserLines.map((line) => ({ ...line, lineType: LineType.SWITCHBOARD })),
    ...basket.emnLines.map((line) => ({ ...line, lineType: LineType.EMN })),
  ];
}
