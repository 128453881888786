<template>
  <div class="basket-summary">
    <telia-heading class="basket-summary__summary-title" tag="h3" variant="title-100">{{
      t("summary.title")
    }}</telia-heading>
    <ul class="basket-summary__billing-text muted">
      <li>
        {{ t("summary.pricesExcludingVat") }}
      </li>
      <li>
        {{ t("summary.deliveryBilling") }}
      </li>
    </ul>

    <div class="basket-summary__fees-text">
      <telia-p class="basket-summary__fees-text-key">{{ t("summary.oneTimeFee") }}</telia-p>
      <telia-p
        t-id="one-time-fee"
        class="basket-summary__fees-text-value fee-display"
        v-html="oneTimeFeePrint"
      ></telia-p>

      <telia-p class="basket-summary__fees-text-key">{{ t("summary.monthlyFee") }}</telia-p>
      <telia-p
        t-id="monthly-fee"
        class="basket-summary__fees-text-value fee-display"
        v-html="monthlyFeePrint"
      ></telia-p>
      <telia-p class="basket-summary__fees-text-key">{{ t("summary.startupFee") }}</telia-p>
      <telia-p
        t-id="startup-fee"
        class="basket-summary__fees-text-value fee-display"
        v-html="startupFeePrint"
      ></telia-p>
    </div>

    <telia-p>
      <div
        t-id="total-committed-fee"
        class="basket-summary__total-committed-fee"
        v-if="hasCommittedFee"
      >
        {{ t("summary.totalCommittedFee", { amount: formatCurrency(totalCommittedFee) }) }}
      </div>
      <div>
        {{ t("summary.additionalFees") }}
      </div>
    </telia-p>
  </div>
</template>
<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import { currentLanguage } from "../../utils/language";
import { translateMixin, translateSetup } from "../../../locale";

interface Props {
  oneTimeFee: string;
  monthlyFee: string;
  startupFee: string;
  totalCommittedFee: number;
}

const props = defineProps<Props>();
translateSetup();

const t = translateMixin.methods.t;
const oneTimeFeePrint: ComputedRef<string> = computed(() => {
  const fee = formatCurrency(props.oneTimeFee);
  return currentLanguage() === "en"
    ? `SEK <span class="basket-summary__pricing">${fee}</span>`
    : `<span class="basket-summary__pricing">${fee}</span> kr`;
});
const monthlyFeePrint: ComputedRef<string> = computed(() => {
  const fee = formatCurrency(props.monthlyFee);
  return currentLanguage() === "en"
    ? `SEK <span class="basket-summary__pricing">${fee}</span>/month`
    : `<span class="basket-summary__pricing">${fee}</span> kr/mån`;
});
const startupFeePrint: ComputedRef<string> = computed(() => {
  const fee = formatCurrency(props.startupFee);
  return currentLanguage() === "en"
    ? `SEK <span class="basket-summary__pricing">${fee}</span>`
    : `<span class="basket-summary__pricing">${fee}</span> kr`;
});
const hasCommittedFee: ComputedRef<boolean> = computed(() => !!props.totalCommittedFee);

function formatCurrency(amount) {
  const formatted = new Intl.NumberFormat(currentLanguage(), {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount);

  return currentLanguage() === "sv"
    ? formatted.replace("kr", "").trim()
    : formatted.replace("SEK", "").trim();
}
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.basket-summary {
  margin-top: $telia-spacing-32;
  margin-bottom: $telia-spacing-24;

  &__summary-title {
    margin-bottom: 0;
  }

  .muted {
    color: #6e6e6e;
    font-size: small;
  }

  .fee-display {
    text-align: right;
  }

  &__billing-text {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &__billing-text > li {
    line-height: 1.5em;
  }

  &__fees-text {
    float: left;
    width: 100%;
    padding: 0;
    margin: $telia-spacing-24 0;
    line-height: $telia-spacing-32;
  }

  &__fees-text-key {
    clear: left;
    float: left;
    margin: 0;
  }

  &__fees-text-value {
    clear: right;
    float: right;
  }

  &__pricing {
    bottom: 0;
    right: 0;
    text-align: right;
    font-size: $telia-spacing-16;
    font-weight: 700;
  }

  &__total-committed-fee {
    line-height: $telia-spacing-32;
  }
}
</style>
