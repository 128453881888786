import { Lang } from "../typings/types";

export default (lang: Lang) => (amount: number) => {
  const formatted = new Intl.NumberFormat(lang, {
    style: "currency",
    currency: "SEK",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount);

  return lang === "sv" ? formatted.replace("kr", "").trim() : formatted.replace("SEK", "").trim();
};

export function sum(a: number, b: number): number {
  return a + b;
}

export function sumAll(...args: number[]) {
  return args.reduce(sum, 0);
}
