<template>
  <div t-id="number-swap-container" class="number-swap">
    <svg class="number-swap__link" height="60" width="16">
      <g fill="none" stroke="black" stroke-width="1">
        <path stroke-dasharray="5,5" d="M16 2 l0 60" />
      </g>
    </svg>
    <div class="number-swap__content">
      <telia-icon name="shuffle" size="lg"></telia-icon>
      <div>
        <telia-heading
          t-id="number-swap-container__title"
          tag="h2"
          :variant="smallLayout ? 'subsection-100' : 'title-200'"
          :class="{ 'number-swap-container__title--small': smallLayout }"
        >
          {{ t("NUMBER_SWAP_TITLE") }}
        </telia-heading>
        <telia-p t-id="number-swap-container__msisdn">
          {{ t(numberSwapLabel, { msisdn: formatMsisdn(details.msisdn) }) }}
        </telia-p>
        <telia-p t-id="number-swap-container__temporary-msisdn" v-if="!details.isTransfer">
          {{
            t("NUMBER_SWAP_TEMPORARY", {
              msisdn: formatMsisdn(details.temporaryMsisdn),
            })
          }}
        </telia-p>
        <telia-p
          v-if="details.desiredDeliveryDate"
          t-id="number-swap-container__temporary-msisdn"
          >{{ t("NUMBER_SWAP_DATE", { date: details.desiredDeliveryDate }) }}</telia-p
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import formatMsisdn from "../../utils/phoneNumber";
import { translateMixin, translateSetup } from "../locale";
import { NumberSwapDetails } from "../../typings/types";

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  details: NumberSwapDetails;
  detailsView?: boolean;
  smallLayout: boolean;
}

const props = withDefaults(defineProps<Props>(), { detailsView: false });

const numberSwapLabel: ComputedRef<string> = computed(() =>
  props.details.isTransfer ? "NUMBER_SWAP_TRANSFER_OF" : "NUMBER_SWAP_PORTING_OF"
);
</script>

<style lang="scss">
@import "~@teliads/components/foundations/spacing/variables";

.number-swap {
  position: relative;
  padding-top: $telia-spacing-64;
  margin-top: calc(-1 * $telia-spacing-20);
  margin-left: $telia-spacing-64;
}

.number-swap-container__title--small {
  margin-bottom: $telia-spacing-4;
  margin-top: 6px;
}

.number-swap__link {
  position: absolute;
  top: 0;
}

.number-swap__content {
  display: flex;
  gap: $telia-spacing-12;
}
</style>
