import * as analytics from "@telia/b2b-web-analytics-wrapper";
import Big from "big.js";
import { Basket } from "../typings/types";
import {
  BasketHardwareLineUI,
  BasketSubscriptionLineUI,
  GetBasketAddonUI,
  GetBasketDatasimUI,
  GetBasketMdsUI,
  GetBasketSubscriptionProductUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";

export function trackRemoveFromBasket(basketLine, linkedLines) {
  let payload = {
    pa: "remove", // enhanced ecommerce action type (:checked used to examplify adding/removing)
    ta: "MyBusiness-Salesflow", // affiliation
    cu: "SEK", // currency
    cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
    cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
    cd94: "New Frame Agreement",
  };

  const products = [basketLine.product, ...extractAddons(basketLine)];

  if (linkedLines) {
    linkedLines.forEach((linkedLine) => {
      return products.push(
        extendProductForGa(linkedLine.product, linkedLine.currentOwnerType),
        ...extractAddons(linkedLine)
      );
    });
  }

  const commitment =
    products.find((product) => product.commitment || product.commitment === 0)?.commitment || 0;
  const operation = products.find((product) => product.operation)?.operation;

  products.forEach((product, i) => {
    const isCombinedHardware = linkedLines?.some(
      (line) => line.product.id === product.id && line.lineType === "HARDWARE"
    );
    const isSubscription =
      basketLine.lineType === "SUBSCRIPTION" && basketLine.product.id === product.id;
    const isLinkedSubscription =
      isSubscription && linkedLines?.some((line) => line.lineType === "HARDWARE");

    let totalUnitFee;
    if (isCombinedHardware) {
      totalUnitFee = new Big(product.oneTimeFee).plus(
        new Big(product.recurringFee).times(commitment)
      );
    } else if (isSubscription) {
      totalUnitFee = new Big(product.oneTimeFee).plus(
        new Big(product.recurringFee).times(commitment || 1)
      );
    } else {
      totalUnitFee = new Big(product.oneTimeFee).plus(product.recurringFee);
    }

    payload = analytics.writeProductData(
      payload,
      { ...product, totalUnitFee: totalUnitFee.toNumber() },
      i + 1,
      1
    );

    payload[`pr${i + 1}cm13`] = +product.recurringFee;
    payload[`pr${i + 1}cm14`] = +product.oneTimeFee;

    if (isCombinedHardware || isLinkedSubscription) {
      payload[`pr${i + 1}cc`] = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
    }
    if (product.commitment) {
      payload[`pr${i + 1}va`] += `-${product.commitment}_MONTHS`;
      payload[`pr${i + 1}cd52`] = `${product.commitment} months`;
    }
    if (isCombinedHardware) {
      payload[`pr${i + 1}cd52`] = `${commitment} months`;
    }
    if (isSubscription || isCombinedHardware) {
      payload[`pr${i + 1}cd12`] = isExtendOperation(operation) ? "extend" : "new";
      if (isSubscription && isExtendOperation(operation)) {
        payload[`pr${i + 1}va`] += `-EXTEND_COMMITMENT`;
      }
    }
    if (product.currentOwnerType) {
      payload[`pr${i + 1}va`] += `-${product.currentOwnerType}`;
    }
  });

  analytics.trackEvent(
    analytics.category.SALESFLOW,
    analytics.action.PRODUCT_REMOVE_FROM_CART,
    basketLine.product.name,
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

export function trackGoToCheckout(basket: Basket) {
  let payload = {
    pa: "checkout", // enhanced ecommerce action type (:checked used to examplify adding/removing)
    ta: "MyBusiness-Salesflow", // affiliation
    cu: "SEK", // currency
    cos: "1",
    col: "start",
    cd4: "MCO", // teliaAnalytics requires cd4 'ProductOffer', look into this
    cd9: "MCO", // teliaAnalytics requires cd9 'Hierarchy ID', look into this
    cd94: "New Frame Agreement",
  };

  const allProducts = {
    ...prepareSubscriptionLinesForGA(basket.lines || [], basket.hardwareLines),
    ...(prepareHardwareLinesForGA(basket.hardwareLines || [], basket.lines) || {}),
    ...prepareProcessLinesForGA(basket.processLines || []),
    ...prepareAccessoryLinesForGA(basket.accessoryLines || []),
    ...prepareSwitchboardUserLinesForGA(basket.switchboardUserLines || []),
  };

  Object.keys(allProducts).forEach((productKey, i) => {
    const product = allProducts[productKey];
    const summedRecurringFee = calculateTotalRecurringFee(product);
    const totalUnitFee = new Big(product.oneTimeFee).plus(summedRecurringFee);

    payload = analytics.writeProductData(
      payload,
      { ...product, totalUnitFee: totalUnitFee.toNumber() },
      i + 1,
      product.quantity
    );
    if (product.trackingVariant) {
      payload[`pr${i + 1}va`] = product.trackingVariant;
    }
    if (product.trackingCC) {
      payload[`pr${i + 1}cc`] = product.trackingCC;
    }

    payload[`pr${i + 1}cm13`] = +product.recurringFee;
    payload[`pr${i + 1}cm14`] = +product.oneTimeFee;

    const commitment = getCommitmentForTracking(product);
    if (commitment || commitment === 0) {
      payload[`pr${i + 1}cd52`] = `${commitment} months`;
    }

    if (product.trackingOperation) {
      payload[`pr${i + 1}cd12`] = product.trackingOperation;
    }

    if (product.huntingGroups) {
      payload[`pr${i + 1}cd7`] = getHuntingGroups(product.huntingGroups);
    }
  });

  analytics.trackEvent(
    analytics.category.SALESFLOW,
    analytics.action.TO_CHECKOUT,
    "Go to checkout",
    "0",
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

function getCommitmentForTracking(product) {
  if (product.commitment || product.commitment === 0) return product.commitment;

  if (product.trackingCommitment || product.trackingCommitment === 0)
    return product.trackingCommitment;

  return null;
}
type TrackingLine = GetBasketSubscriptionProductUI &
  GetBasketAddonUI &
  GetBasketDatasimUI &
  GetBasketMdsUI;
interface BasketTrackingLine extends Partial<TrackingLine> {
  trackingVariant?: string;
  trackingCC?: "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
  trackingOperation?: "extend" | "new";
}

function prepareSubscriptionLinesForGA(
  subscriptionLines: BasketSubscriptionLineUI[],
  basketLines: BasketHardwareLineUI[]
) {
  return subscriptionLines.reduce((acc, line) => {
    const hasRelatedHardware = !!basketLines.some(
      (hwLine) => hwLine.relationId === line.relationId
    );
    const products: BasketTrackingLine[] = [
      line.product,
      ...(line.addons || []),
      ...(line.datasims || []),
    ];
    if (line.mds) {
      products.push(line.mds);
    }
    products.forEach((product) => {
      const id = buildSubscriptionId(product);
      if (id in acc) {
        acc[id].quantity++;
      } else {
        product.trackingVariant = id;

        if (hasRelatedHardware) {
          product.trackingCC = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
        }

        product.trackingOperation = isExtendOperation(product.operation) ? "extend" : "new";

        acc[id] = {
          ...product,
          quantity: 1,
        };
      }
    });
    return acc;
  }, {});
}

function buildSubscriptionId(product): string {
  let id = product.productCode;
  if (product.commitment) {
    id += `-${product.commitment}_MONTHS`;
  }

  if (isExtendOperation(product.operation)) {
    id += "-EXTEND_COMMITMENT";
  }

  return id;
}

function prepareHardwareLinesForGA(hardwareLines, subscriptionLines) {
  return hardwareLines.reduce((acc, line) => {
    const relatedSubscription = subscriptionLines.find(
      (subLine) => subLine.relationId === line.relationId
    );
    const hasRelation = !!relatedSubscription;
    const isUpfront = line.upfrontPayment;
    const isExtend = isExtendOperation(relatedSubscription?.product?.operation);
    const id = buildHardwareId(line.product, hasRelation, isUpfront, isExtend);

    if (id in acc) {
      acc[id].quantity++;
    } else {
      if (hasRelation) {
        line.product.trackingCC = "COMBINED_HARDWARE_SUBSCRIPTION_OFFER";
        line.product.trackingCommitment = relatedSubscription.product.commitment;

        line.product.trackingOperation = isExtend ? "extend" : "new";
      }
      acc[id] = {
        ...line.product,
        quantity: 1,
      };
    }
    return acc;
  }, {});
}

function buildHardwareId(product, hasRelation, isUpfront, isExtend) {
  let id = product.productCode;
  if (hasRelation) {
    id += product.oneTimeFee;
    id += product.recurringFee;

    if (isUpfront) {
      id += "-UPFRONT";
    }
    if (isExtend) {
      id += "-EXTEND";
    }
  }
  return id;
}

function prepareProcessLinesForGA(processLines) {
  return processLines.reduce((acc, line) => {
    const id = buildProcessId(line);
    if (id in acc) {
      acc[id].quantity++;
    } else {
      line.product.trackingVariant = id;
      acc[id] = {
        ...line.product,
        quantity: 1,
      };
    }
    return acc;
  }, {});
}

function prepareAccessoryLinesForGA(accessoryLines) {
  return accessoryLines.reduce((acc, line) => {
    if (line.product.productCode in acc) {
      acc[line.product.productCode].quantity++;
    } else {
      acc[line.product.productCode] = {
        ...line.product,
        quantity: 1,
      };
    }
    return acc;
  }, {});
}

function prepareSwitchboardUserLinesForGA(switchboardUserLine) {
  return switchboardUserLine.reduce((sb, line) => {
    const products = [line.product, ...(line.addons || [])];
    products.forEach((product) => {
      if (product.productCode in sb) {
        sb[product.productCode].quantity++;
      } else {
        sb[product.productCode] = {
          ...product,
          quantity: 1,
        };
      }
    });
    return sb;
  }, {});
}

function buildProcessId(line) {
  return `${line.product.productCode}-${line.currentOwnerType}`;
}

function extendProductForGa(product, currentOwnerType) {
  return { ...product, currentOwnerType: currentOwnerType };
}

function extractAddons(line) {
  return [...(line.addons || []), ...(line.datasims || []), ...(line.mds ? [line.mds] : [])];
}

function calculateTotalRecurringFee(product) {
  if (product.commitment) {
    return new Big(product.recurringFee).times(product.commitment);
  } else if (product.trackingCommitment) {
    return new Big(product.recurringFee).times(product.trackingCommitment);
  } else {
    return new Big(product.recurringFee);
  }
}

function isExtendOperation(operation) {
  return operation === "EXTEND_SUBSCRIPTION";
}

function getHuntingGroups(huntingGroups) {
  return "Number of hunting groups: " + (huntingGroups?.length | 0);
}
