<template>
  <div t-id="b2b-hardware-basket-item" class="hardware-basket-item">
    <div class="hardware-basket-item-container">
      <div class="hardware-basket-item-image">
        <img t-id="hardware-basket-item-image" :src="image" v-if="imageUrl" />
        <telia-icon
          name="accessories"
          size="lg"
          class="hardware-basket-item-image__placeholder"
          v-if="showAccessoryPlaceholder"
          t-id="hardware-basket-item__accessory-placeholder-image"
        />
        <telia-icon
          name="smartphone"
          size="lg"
          class="hardware-basket-item-image__placeholder"
          v-if="showMobileDevicePlaceholder"
          t-id="hardware-basket-item__mobile-device-placeholder-image"
        />
      </div>
      <div class="hardware-basket-item-info">
        <telia-heading
          t-id="hardware-basket-item-name"
          class="hardware-basket-item__title"
          tag="h2"
          :variant="smallLayout ? 'subsection-100' : 'title-200'"
        >
          {{ name }}
        </telia-heading>
        <div class="hardware-basket-item-info-subtitle">
          <telia-p t-id="hardware-basket-item-subtitle" v-if="detailedInformation">
            {{ detailedInformation }}
          </telia-p>
          <telia-p
            v-if="stockStatus && detailedInformation"
            class="hardware-basket-item-info-subtitle-divider"
          >
            &nbsp;|&nbsp;
          </telia-p>
          <b2b-stock-status
            v-if="!!stockStatus"
            t-id="hardware-basket-item-subtitle__stock-status"
            :status="hardWareStockStatus"
            :available-from="stockAvailableFrom"
            :right-side-status-indicator="true"
          />
        </div>
        <div t-id="hardware-basket-item-username">
          <telia-p v-if="!!user">{{ user }}</telia-p>
        </div>
      </div>
      <div class="hardware-basket-item-pricing" v-if="hasPrice">
        <div v-if="hasOneTimeFee" t-id="hardware-basket-item-one-time-fee">
          <telia-p v-html="oneTimeFeePrint"></telia-p>
        </div>
        <div v-if="hasRecurringFee" t-id="hardware-basket-item-recurring-fee">
          <telia-p v-html="recurringFeePrint"></telia-p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import { currentLanguage } from "@telia/b2b-i18n";
import "@telia/b2b-stock-status";
import { formatMemory } from "../utils/formatters";
import { Lang } from "../typings/types";
import FormatCurrency from "../utils/currency";
import { StockStatus } from "@telia/b2b-stock-status";

const pageLanguage: Lang = currentLanguage() as Lang;
const formatCurrency = FormatCurrency(pageLanguage);

interface Props {
  name: string;
  oneTimeFee: string;
  recurringFee?: string;
  imageUrl?: string;
  memory?: string;
  color: string;
  user?: string;
  smallLayout?: boolean;
  stockStatus: string;
  stockAvailableFrom?: string;
  tscid?: string;
  isAccessory?: boolean;
  isPresale?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  smallLayout: false,
  isAccessory: false,
  isPresale: false,
});

const detailedInformation = computed<string>(() => {
  let formattedDetails: string[] = [];
  if (props.memory) {
    formattedDetails.push(formatMemory(props.memory));
  }
  if (props.color) {
    formattedDetails.push(props.color);
  }
  return formattedDetails.join(", ");
});

const hasPrice: ComputedRef<boolean> = computed<boolean>(
  () => hasOneTimeFee.value || hasRecurringFee.value
);
const oneTimeFeePrint: ComputedRef<string> = computed<string>(() => {
  const fee = formatCurrency(parseInt(props.oneTimeFee));
  return currentLanguage() == "en" ? `SEK <b>${fee}</b>` : `<b>${fee}</b> kr`;
});
const recurringFeePrint: ComputedRef<string> = computed<string>(() => {
  const fee = formatCurrency(parseFloat(props.recurringFee ?? "0"));
  return currentLanguage() == "en" ? `SEK <b>${fee}</b>/month` : `<b>${fee}</b> kr/mån`;
});
const image: ComputedRef<string> = computed<string>(() =>
  props.imageUrl ? `${props.imageUrl}?h=50` : ""
);
const showAccessoryPlaceholder: ComputedRef<boolean> = computed<boolean>(
  () => !props.imageUrl && props.isAccessory
);
const showMobileDevicePlaceholder: ComputedRef<boolean> = computed<boolean>(
  () => !props.imageUrl && !props.isAccessory
);
const hasOneTimeFee: ComputedRef<boolean> = computed<boolean>(
  () => !!props.oneTimeFee && props.oneTimeFee !== "0"
);
const hasRecurringFee: ComputedRef<boolean> = computed<boolean>(
  () => !!props.recurringFee && props.recurringFee !== "0"
);

const hardWareStockStatus: ComputedRef<string> = computed(() => {
  if (props.isPresale) {
    ("PRE_SALES");
  }
  return props.stockStatus;
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.hardware-basket-item {
  padding: $telia-spacing-12 0;
}

.hardware-basket-item-container {
  display: grid;
  grid-template-columns: $telia-spacing-48 5fr 1fr;
  grid-column-gap: $telia-spacing-12;
  grid-row-gap: 0;
  position: relative;
}

.hardware-basket-item-image {
  margin-top: $telia-spacing-2;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: fit-content;
}

.hardware-basket-item-image__placeholder {
  color: $telia-gray-300;
}

.hardware-basket-item-info {
  margin-bottom: $telia-spacing-24;
}

.hardware-basket-item__title {
  margin-bottom: $telia-spacing-4;
}

.hardware-basket-item-info-subtitle {
  line-height: 26px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &-divider {
    @media screen and (max-width: 1250px) {
      display: none;
    }
  }
}

.hardware-basket-item-username--not-set {
  color: #7f7f7f;
}

.hardware-basket-item-pricing {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}
</style>
