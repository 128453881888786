import { defineCustomElement } from "vue";

import SubscriptionBasketItemComponent from "./components/b2b-subscription-basket-item/subscription-basket-item.entry";
import LinkedBasketItemComponent from "./components/b2b-linked-basket-item/linked-basket-item.ce.vue";
import HardwareBasketItemComponent from "./components/b2b-hardware-basket-item/hardware-basket-item.ce.vue";
import BasketWrapperComponent from "./b2b-basket-wrapper.entry";
import ProductCartComponent from "./components/b2b-product-cart/product-cart.entry";

export function registerCustomElements() {
  const BasketWrapper = defineCustomElement(BasketWrapperComponent as any);
  const LinkedBasketItem = defineCustomElement(LinkedBasketItemComponent);
  const SubscriptionBasketItem = defineCustomElement(SubscriptionBasketItemComponent as any);
  const HardwareBasketItem = defineCustomElement(HardwareBasketItemComponent);
  const ProductCart = defineCustomElement(ProductCartComponent as any);
  customElements.define("b2b-basket-wrapper", BasketWrapper);
  customElements.define("b2b-linked-basket-item", LinkedBasketItem);
  customElements.define("b2b-subscription-basket-item", SubscriptionBasketItem);
  customElements.define("b2b-hardware-basket-item", HardwareBasketItem);
  customElements.define("b2b-product-cart", ProductCart);
}
