import { corpBasketManagement } from "@telia/b2b-rest-client";
import { BasketDTO } from "@telia/b2b-rest-client/dist/corp-basket-management";
import type { Lang } from "./typings/types";

export const getBasketSpock = async (
  scopeId: string,
  tscid: string,
  lang?: Lang
): Promise<BasketDTO> => {
  return corpBasketManagement.BasketControllerService.getBasket(scopeId, tscid, lang);
};
