import {
  BasketHardwareLineUI,
  BasketAccessoryLineUI,
  BasketUI,
  BasketSubscriptionLineUI,
  BasketProcessLineUI,
  BasketSwitchboardUserLineUI,
  GetBasketAccessoryProductUI,
  GetBasketAddonUI,
  GetBasketDatasimUI,
  GetBasketHardwareProductUI,
  GetBasketMdsUI,
  GetBasketSubscriptionProductUI,
  BasketEmnSubscriptionLineUI,
  EmnSubscriptionProductUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";
import { ProductAvailability as ProductAvailabilityUI } from "@telia/b2b-rest-client/dist/corp-stock-status";
import { HuntingGroupsResultDTO } from "@telia/b2b-rest-client/dist/corp-switchboards";

export enum BasketSource {
  SPOCK = "spock",
  AMANDA = "amanda",
}

export enum Lang {
  SV = "sv",
  EN = "en",
}

export enum LineType {
  SUBSCRIPTION = "SUBSCRIPTION",
  HARDWARE = "HARDWARE",
  PROCESS = "PROCESS",
  ACCESSORY = "ACCESSORY",
  SWITCHBOARD = "SWITCHBOARD",
  EMN = "EMN",
}

export interface Organisation {
  name?: string;
  tscid?: string;
  organizationNumber?: string;
}

export enum Operation {
  EXTEND_SUBSCRIPTION = "EXTEND_SUBSCRIPTION",
  NEW_SUBSCRIPTION = "NEW_SUBSCRIPTION",
}

export interface BillingAccount {
  accountNumber: string;
  billingAccountReference: string;
}

export enum DeliveryMethod {
  ESIM = "ESIM",
  SIMCARD = "SIMCARD",
  ICC = "ICC",
  KEEP = "KEEP",
}

export interface DataSim {
  name: string;
  recurringFee: string;
}

export interface Mds {
  recurringFee: string;
}

export interface MappedAddon {
  name: string | undefined;
  oneTimeFee: string | undefined;
  recurringFee: string | undefined;
}

export interface MappedHuntingGroup {
  id: string | undefined;
  name: string | undefined;
}

export enum OwnerType {
  ORGANIZATION = "ORGANIZATION",
  PRIVATE = "PRIVATE",
}

export interface NumberSwapDetails {
  msisdn: string;
  temporaryMsisdn: string | null;
  currentOwnerSsn: string | null;
  currentOwnerEmail: string;
  currentOwnerOrganization: string | null;
  desiredDeliveryDate?: string;
  oneTimeFee: string;
  currentOwnerType: ProcessLine["currentOwnerType"];
  isTransfer: boolean;
}

export interface SwitchboardUserDetails {
  name: string;
  recurringFee: string;
  oneTimeFee: string;
  totalRecurringFee: string;
  totalOneTimeFee: string;
  addons: MappedAddon[];
  huntingGroups: MappedHuntingGroup[];
}

export interface SubscriptionItemPrice {
  recurringFee: string;
  oneTimeFee: string;
  increasedMrcForMobileDevice: string;
}

export interface HardwareItemPrice {
  recurringFee?: string;
  oneTimeFee: string;
}

export interface CombinationItemPrice {
  subscription: SubscriptionItemPrice;
  hardware: HardwareItemPrice;
}

export interface CombinationItem {
  relationId: string;
  hardware: BasketLineWithStockStatus<HardwareLine>;
  subscription: SubscriptionWithDetails;
}

export type CombinationItemWithPrices = CombinationItem & { prices: CombinationItemPrice };

export type DeepRequired<T> = { [K in keyof T]: DeepRequired<T[K]> } & Required<T>;
export type ProductAvailability = DeepRequired<ProductAvailabilityUI>;
export type Basket = DeepRequired<BasketUI>;
export type HardwareLine = DeepRequired<BasketHardwareLineUI>;
export type AccessoryLine = DeepRequired<BasketAccessoryLineUI>;
export type SubscriptionLine = DeepRequired<BasketSubscriptionLineUI>;
export type ProcessLine = DeepRequired<BasketProcessLineUI>;
export type EmnLine = DeepRequired<BasketEmnSubscriptionLineUI>;
export type Line =
  | SubscriptionLine
  | HardwareLine
  | ProcessLine
  | AccessoryLine
  | SwitchboardUserLine
  | EmnLine;
export type SwitchboardUserLine = DeepRequired<BasketSwitchboardUserLineUI>;
export type BasketLineWithStockStatus<T extends HardwareLine | AccessoryLine> = T & {
  stockStatus: ProductAvailability | { status: string; availableFrom: string | undefined };
};
export type AnyBasketLineType<T extends Line> = T & {
  lineType: LineType;
};
export type HuntingGroupsResult = DeepRequired<HuntingGroupsResultDTO>;

export type SubscriptionWithDetails = SubscriptionLine & {
  numberSwapDetails: NumberSwapDetails | undefined;
  switchboardUserDetails: SwitchboardUserDetails | undefined;
  mds: Mds;
  datasims: DataSim[];
};

export type LineProductItem =
  | EmnSubscriptionProductUI
  | GetBasketHardwareProductUI
  | GetBasketAccessoryProductUI
  | GetBasketAddonUI
  | GetBasketDatasimUI
  | GetBasketMdsUI;

export function isAccessoryProduct(
  basketProduct: LineProductItem
): basketProduct is GetBasketAccessoryProductUI {
  return (basketProduct as GetBasketAccessoryProductUI).subCategory === "accessories";
}
export function isHardwareProduct(
  basketProduct: LineProductItem
): basketProduct is GetBasketHardwareProductUI {
  return (basketProduct as GetBasketAccessoryProductUI).subCategory === "mobiledevice";
}

export function isSubscriptionProduct(
  basketProduct: LineProductItem
): basketProduct is GetBasketSubscriptionProductUI {
  return !!(basketProduct as GetBasketSubscriptionProductUI).operation;
}

export function isSubscriptionLine(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<SubscriptionLine> {
  return line.lineType === LineType.SUBSCRIPTION;
}

export function isSwitchboardLineType(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<SwitchboardUserLine> {
  return line.lineType === LineType.SWITCHBOARD;
}

export function isHardwareLineType(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<HardwareLine> {
  return line.lineType === LineType.HARDWARE;
}

export function isAccessoryLineType(
  line: AnyBasketLineType<Line>
): line is AnyBasketLineType<AccessoryLine> {
  return line.lineType === LineType.ACCESSORY;
}

export function isEmnLineType(line: AnyBasketLineType<Line>): line is AnyBasketLineType<EmnLine> {
  return line.lineType === LineType.EMN;
}
