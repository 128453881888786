import { corpProductOrder } from "@telia/b2b-rest-client";
import { BasketUI } from "@telia/b2b-rest-client/dist/corp-product-order";
import type { Lang } from "./typings/types";

export const getBasketAmanda = async (
  scopeId: string,
  tscid: string,
  lang?: Lang
): Promise<BasketUI> => {
  return corpProductOrder.BasketControllerService.getBasket(scopeId, tscid, lang);
};

export const isBlacklistedForFraud = async (scopeId: string, tscid: string): Promise<boolean> => {
  return corpProductOrder.OrganizationInformationControllerService.isBlackListed(scopeId, tscid);
};
