<template>
  <div t-id="b2b-product-cart" class="product-cart">
    <div
      :t-id="`linked-line-${String(index)}`"
      v-for="(item, index) in combinationItems"
      :key="item.relationId"
      class="basket-item"
    >
      <b2b-deletable-item
        :t-id="`linked-line-${String(index)}-deletable-item`"
        :error.prop="showError(item.subscription)"
        :item-id="item.subscription.id"
        :pending-deletion.prop="itemIdsBeingRemoved.has(item.subscription.id)"
        @delete-item="removeItem"
      >
        <B2bLinkedBasketItem>
          <template #hardware>
            <B2bHardwareBasketItem
              :name="item.hardware.product.name"
              :memory="item.hardware.product.memory"
              :color="item.hardware.product.color"
              :user="item.hardware.user.name"
              :one-time-fee="item.prices.hardware.oneTimeFee"
              :recurring-fee="item.prices.hardware.recurringFee"
              :image-url="item.hardware.product.imageUrl"
              :stock-status="item.hardware.stockStatus.status"
              :stock-available-from="item.hardware.stockStatus.availableFrom"
              :is-presale="item.hardware.presales"
            />
          </template>
          <template #subscription>
            <B2bSubscriptionBasketItem
              :name="item.subscription.product.name"
              :user-name="item.subscription.user.name"
              :msisdn="item.subscription.msisdn"
              :recurring-fee="item.prices.subscription.recurringFee"
              :one-time-fee="item.prices.subscription.oneTimeFee"
              :increased-mrc-for-mobile-device="
                item.prices.subscription.increasedMrcForMobileDevice
              "
              :commitment-period="item.subscription.product.commitment"
              :operation="Operation[item.subscription.product.operation]"
              :delivery-method="
                DeliveryMethod[getDeliveryMethodForSubscriptionLine(item.subscription)]
              "
              :addons="getAddonsForSubscriptionLine(item.subscription)"
              :datasims="item.subscription.datasims"
              :mds="item.subscription.mds"
              :number-swap-details="item.subscription.numberSwapDetails"
              :switchboard-user-details="item.subscription.switchboardUserDetails"
            />
          </template>
        </B2bLinkedBasketItem>
      </b2b-deletable-item>
    </div>
    <div
      :t-id="`subscription-line-${String(index)}`"
      v-for="(line, index) in standaloneSubscriptionItems"
      :key="line.id"
      class="basket-item"
    >
      <b2b-deletable-item
        :t-id="`subscription-line-${String(index)}-deletable-item`"
        :error.prop="showError(line)"
        :item-id="line.id"
        :pending-deletion.prop="itemIdsBeingRemoved.has(line.id)"
        @delete-item="removeItem"
      >
        <B2bSubscriptionBasketItem
          :name="line.product.name"
          :user-name="line.user.name"
          :msisdn="line.msisdn"
          :recurring-fee="line.recurringFee"
          :one-time-fee="line.oneTimeFee"
          :commitment-period="line.product.commitment"
          :delivery-method="DeliveryMethod[getDeliveryMethodForSubscriptionLine(line)]"
          :addons="getAddonsForSubscriptionLine(line)"
          :datasims="line.datasims"
          :mds="line.mds"
          :number-swap-details="line.numberSwapDetails"
          :switchboard-user-details="line.switchboardUserDetails"
        />
      </b2b-deletable-item>
    </div>
    <div
      :t-id="`hardware-line-${String(index)}`"
      v-for="(hline, index) in standaloneHardwareItems"
      :key="hline.id"
      class="basket-item"
    >
      <b2b-deletable-item
        :t-id="`hardware-line-${String(index)}-deletable-item`"
        :error.prop="showError(hline)"
        :item-id="hline.id"
        :pending-deletion.prop="itemIdsBeingRemoved.has(hline.id)"
        @delete-item="removeItem"
      >
        <B2bHardwareBasketItem
          :name="hline.product.name"
          :memory="hline.product.memory"
          :color="hline.product.color"
          :user="hline.user.name"
          :one-time-fee="hline.product.oneTimeFee"
          :recurring-fee="hline.product.recurringFee"
          :image-url="hline.product.imageUrl"
          :stock-status="hline.stockStatus.status"
          :stock-available-from="hline.stockStatus.availableFrom"
        />
      </b2b-deletable-item>
    </div>
    <div
      :t-id="`accessory-line-${String(index)}`"
      v-for="(aline, index) in accessoryLinesWithStockStatus"
      :key="aline.id"
      class="basket-item"
    >
      <b2b-deletable-item
        :t-id="`accessory-line-${String(index)}-deletable-item`"
        :error.prop="showError(aline)"
        :item-id="aline.id"
        :pending-deletion.prop="itemIdsBeingRemoved.has(aline.id)"
        @delete-item="removeItem"
      >
        <B2bHardwareBasketItem
          :name="aline.product.name"
          :color="aline.product.color"
          :one-time-fee="aline.product.oneTimeFee"
          :image-url="aline.product.imageUrl"
          :stock-status="aline.stockStatus.status"
          :stock-available-from="aline.stockStatus.availableFrom"
          is-accessory
        />
      </b2b-deletable-item>
    </div>
    <div
      :t-id="`emn-line-${String(index)}`"
      v-for="(eline, index) in emnLines"
      :key="eline.id"
      class="basket-item"
    >
      <b2b-deletable-item
        :t-id="`emn-line-${String(index)}-deletable-item`"
        :error.prop="showError(eline)"
        :item-id="eline.id"
        :pending-deletion.prop="itemIdsBeingRemoved.has(eline.id)"
        @delete-item="removeItem"
      >
        <B2bEmnSubscriptionBasketItem
          :name="eline.product.name"
          :quantity="eline.quantity"
          :ip-range-start="eline.product.ipRangeStart"
          :site="eline.product.siteName"
          :one-time-fee="eline.product.oneTimeFee"
          :recurring-fee="eline.product.recurringFee"
          :total-one-time-fee="eline.oneTimeFee"
          :total-recurring-fee="eline.recurringFee"
        />
      </b2b-deletable-item>
    </div>
    <basket-summary
      :startup-fee="basket.startupFee"
      :one-time-fee="basket.totalOneTimeFee"
      :monthly-fee="basket.totalRecurringFee"
      :total-committed-fee="totalCommittedFee"
    />
    <telia-button
      t-id="goToCheckout"
      variant="expressive"
      size="md"
      :href="checkoutUrl"
      class="checkout-button"
      :disabled="isBlacklisted"
      @click.prevent="goToCheckout"
    >
      {{ t("toCheckout") }}
    </telia-button>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, PropType, Ref, ref, watchEffect } from "vue";
import {
  AccessoryLine,
  AnyBasketLineType,
  Basket,
  BasketLineWithStockStatus,
  CombinationItem,
  CombinationItemPrice,
  CombinationItemWithPrices,
  DeliveryMethod,
  HardwareLine,
  Line,
  LineType,
  MappedAddon,
  Operation,
  ProductAvailability,
  SubscriptionLine,
  SubscriptionWithDetails,
} from "../typings/types";
import {
  buildLinesWithStockStatus,
  findRelatedHardware,
  findRelatedSubscription,
  mapToSubscriptionWithDetails,
} from "../utils/basketLines";
import Big from "big.js";
import {
  corpProductOrder,
  corpStockStatus,
  corpSwitchboards,
  corpNumberBooking,
} from "@telia/b2b-rest-client";
import { trackGoToCheckout, trackRemoveFromBasket } from "../utils/gaTracking";
import { trackCheckout, trackRemoveItem } from "../utils/ga4Helper";
import { translateMixin, translateSetup } from "../../locale";
import BasketSummary from "./components/basket-summary.ce.vue";
import B2bHardwareBasketItem from "../b2b-hardware-basket-item/hardware-basket-item.ce.vue";
import B2bSubscriptionBasketItem from "../b2b-subscription-basket-item/subscription-basket-item.ce.vue";
import B2bLinkedBasketItem from "../b2b-linked-basket-item/linked-basket-item.ce.vue";
import B2bEmnSubscriptionBasketItem from "../b2b-emn-subscription-basket-item/emn-subscription-basker-item.ce.vue";
import "@telia/b2b-deletable-item";

const props = defineProps({
  uuid: { type: String },
  basket: { type: Object as PropType<Basket>, required: true },
  scopeId: { type: String, required: true },
  tscid: { type: String, required: true },
  isBlacklisted: { type: Boolean, default: false },
  isReplicatedMfa: { type: Boolean, default: false },
});

const emit = defineEmits(["remove-item-begin", "remove-item-end"]);
translateSetup();

const t = translateMixin.methods.t;
const productStockStatuses: Ref<ProductAvailability[]> = ref([]);
const fetchingStockStatusError: Ref<boolean> = ref(false);
const removeErrorId: Ref<string | null> = ref(null);
const itemIdsBeingRemoved: Ref<Set<string>> = ref(new Set<string>());

const emnLines = computed(() => {
  return props.basket.emnLines;
});

const hardwareLinesWithStockStatus: ComputedRef<BasketLineWithStockStatus<HardwareLine>[]> =
  computed(() =>
    buildLinesWithStockStatus<HardwareLine>(
      props.basket?.hardwareLines,
      productStockStatuses.value,
      fetchingStockStatusError.value
    )
  );
const accessoryLinesWithStockStatus: ComputedRef<BasketLineWithStockStatus<AccessoryLine>[]> =
  computed(() =>
    buildLinesWithStockStatus<AccessoryLine>(
      props.basket?.accessoryLines,
      productStockStatuses.value,
      fetchingStockStatusError.value
    )
  );
const standaloneHardwareItems: ComputedRef<BasketLineWithStockStatus<HardwareLine>[]> = computed(
  () =>
    hardwareLinesWithStockStatus.value.filter(
      (subscription) => !findRelatedSubscription(subscription.relationId, props.basket?.lines)
    )
);
const standaloneSubscriptionItems: ComputedRef<SubscriptionWithDetails[]> = computed(
  () =>
    props.basket?.lines
      .filter(
        (subscription) => !findRelatedHardware(subscription.relationId, props.basket?.hardwareLines)
      )
      .map((subscription) =>
        mapToSubscriptionWithDetails(
          subscription,
          props.basket?.processLines,
          props.basket?.switchboardUserLines
        )
      ) ?? []
);
const combinationItems: ComputedRef<CombinationItemWithPrices[]> = computed(() => {
  const lines = hardwareLinesWithStockStatus.value
    .map((hardware) => {
      const relatedSubscription = findRelatedSubscription(hardware.relationId, props.basket?.lines);
      return {
        relationId: hardware.relationId,
        hardware,
        subscription: relatedSubscription
          ? mapToSubscriptionWithDetails(
              relatedSubscription,
              props.basket?.processLines,
              props.basket?.switchboardUserLines
            )
          : null,
      };
    })
    .filter(
      (combination) => !!combination.subscription && combination.relationId
    ) as CombinationItem[];

  return lines.map((line) => ({
    ...line,
    prices: getPricesForCombinationItem(line),
  }));
});
const totalCommittedFee: ComputedRef<number> = computed(() => {
  const oneTimeCostsSum = sum([
    props.basket.startupFee,
    ...standaloneHardwareItems.value.map((item) => item.product.oneTimeFee),
    ...standaloneSubscriptionItems.value.map((item) => item.product.oneTimeFee),
    ...accessoryLinesWithStockStatus.value.map((item) => item.product.oneTimeFee),
    ...combinationItems.value.map((combo) => combo.subscription.product?.oneTimeFee),
    ...combinationItems.value.map((combo) => combo.hardware.product.oneTimeFee),
  ]);

  const recurringCostsSum = sum([
    ...standaloneSubscriptionItems.value.map((item) =>
      new Big(item.product.recurringFee || 0).times(item.product.commitment || 0).toNumber()
    ),
    ...combinationItems.value.map((combo) =>
      new Big(combo.subscription.product?.recurringFee || 0)
        .plus(combo.hardware.product.recurringFee || 0)
        .times(combo.subscription.product?.commitment || 0)
        .toNumber()
    ),
  ]);

  return new Big(oneTimeCostsSum).plus(recurringCostsSum).toNumber();
});

const checkoutUrl: ComputedRef<string | null> = computed(() => {
  return props.isBlacklisted
    ? null
    : `/foretag/mybusiness/${props.scopeId}/bestall/${props.tscid}/checka-ut`;
});

const allBasketLines: ComputedRef<AnyBasketLineType<Line>[]> = computed(() => {
  return [
    ...(props.basket?.lines?.map((line) => {
      return { ...line, lineType: LineType.SUBSCRIPTION };
    }) || []),
    ...(props.basket?.hardwareLines.map((line) => {
      return { ...line, lineType: LineType.HARDWARE };
    }) || []),
    ...(props.basket?.processLines?.map((line) => {
      return { ...line, lineType: LineType.PROCESS };
    }) || []),
    ...(props.basket?.accessoryLines?.map((line) => {
      return { ...line, lineType: LineType.ACCESSORY };
    }) || []),
  ];
});

async function fetchStockStatus() {
  const statusQuantities = new Map<string, number>();
  const itemsWithStock = [
    ...(props.basket?.hardwareLines || []),
    ...(props.basket?.accessoryLines || []),
  ];

  itemsWithStock.forEach((line) => {
    if (statusQuantities[line.product.sapId]) {
      statusQuantities.set(line.product.sapId, statusQuantities[line.product.sapId] + 1);
    } else {
      statusQuantities.set(line.product.sapId, 1);
    }
  });

  let productStatusRequest: { sapId: string; quantity: number }[] = [];
  for (const [sapId, quantity] of statusQuantities.entries()) {
    productStatusRequest = [...productStatusRequest, { sapId, quantity }];
  }

  fetchingStockStatusError.value = false;
  try {
    productStockStatuses.value =
      (await corpStockStatus.StockStatusControllerService.getMultiUsingPost(
        productStatusRequest
      )) as ProductAvailability[];
  } catch (error) {
    fetchingStockStatusError.value = true;
  }
}

function showError(line) {
  return line.id === removeErrorId.value;
}

async function removeItem(event) {
  const [itemId] = event.detail;
  try {
    removeErrorId.value = null;
    itemIdsBeingRemoved.value.add(itemId);
    emit("remove-item-begin", true);
    await corpProductOrder.BasketControllerService.deleteFromBasket(
      props.scopeId,
      props.tscid,
      itemId
    );

    const basketLineItem = allBasketLines.value.find((line) => line.id === itemId);
    if (basketLineItem) {
      const linkedLines: AnyBasketLineType<Line>[] = allBasketLines.value.filter(
        (line) => line.relationId === basketLineItem.relationId && line.id !== basketLineItem.id
      );

      if (basketLineItem.lineType === LineType.SUBSCRIPTION) {
        const subscriptionLine = basketLineItem as SubscriptionLine;
        try {
          const reservedNumber =
            await corpNumberBooking.PublicNumberBookingControllerService.reservedNumberStatus(
              props.scopeId,
              props.tscid,
              subscriptionLine.msisdn
            );
          if (reservedNumber.status === "BOOKED" && reservedNumber.msisdn) {
            corpNumberBooking.PublicNumberBookingControllerService.releaseReservedNumber(
              props.scopeId,
              props.tscid,
              reservedNumber.msisdn
            );
          }
        } catch {
          //reserved number will be booked until released in gsm ahs
        }
      }
      trackRemoveFromBasket(basketLineItem, linkedLines);
      trackRemoveItem(basketLineItem, linkedLines, props.isReplicatedMfa);
    }
  } catch (error) {
    removeErrorId.value = itemId;
  } finally {
    itemIdsBeingRemoved.value.delete(itemId);
    emit("remove-item-end", props.tscid);
  }
}

function getDeliveryMethodForSubscriptionLine(line: SubscriptionLine): string {
  if (line.simcard.type === "SIMCARD" && line.simcard.iccNumber) {
    return "ICC";
  }
  return line.simcard.type;
}

function getAddonsForSubscriptionLine(line: SubscriptionLine): MappedAddon[] {
  return line.addons ?? [];
}

function sum(items) {
  return items.reduce((acc, value) => acc.plus(value || 0), new Big(0)).toNumber();
}

function getPricesForCombinationItem(item: CombinationItem): CombinationItemPrice {
  const subscriptionOneTimeFee = item.subscription.oneTimeFee;
  const subscriptionRecurringFee = item.subscription.recurringFee;
  const hardwareOneTimeFee = item.hardware.product.oneTimeFee;
  const hardwareRecurringFee = item.hardware.product.recurringFee;
  const hasCommitment = !!item.subscription.product?.commitment;
  const isUpfrontPayment = item.hardware.upfrontPayment;

  return {
    subscription: {
      recurringFee: hasCommitment
        ? new Big(subscriptionRecurringFee).plus(hardwareRecurringFee).toString()
        : subscriptionRecurringFee,
      oneTimeFee: hasCommitment
        ? new Big(subscriptionOneTimeFee).plus(isUpfrontPayment ? 0 : hardwareOneTimeFee).toString()
        : subscriptionOneTimeFee,
      increasedMrcForMobileDevice: hasCommitment && !isUpfrontPayment ? hardwareRecurringFee : "0",
    },
    hardware: {
      recurringFee: hasCommitment ? undefined : hardwareRecurringFee,
      oneTimeFee:
        hasCommitment && isUpfrontPayment
          ? hardwareOneTimeFee
          : hasCommitment
            ? "0"
            : hardwareOneTimeFee,
    },
  };
}

function goToCheckout() {
  if (!props.isBlacklisted && checkoutUrl.value) {
    trackGoToCheckout(props.basket);
    trackCheckout(props.basket, props.isReplicatedMfa);

    window.location.href = checkoutUrl.value;
  }
}
watchEffect(() => {
  if (props.basket.hardwareLines?.length || props.basket.accessoryLines?.length) {
    fetchStockStatus();
  }
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.product-cart {
  .basket-item {
    border-top: 0.1rem solid $telia-gray-200;
    border-bottom: 0.1rem solid $telia-gray-200;
    padding-top: $telia-spacing-24;
  }

  .basket-item ~ .basket-item {
    border-top: 0;
  }
}

.checkout-button {
  margin-top: $telia-spacing-12;
}
</style>
