<template>
  <div t-id="b2b-linked-basket-item" class="b2b-linked-basket-item">
    <div
      t-id="b2b-linked-basket-item-connector"
      class="b2b-linked-basket-item__connector"
      :style="`height: ${distance - imageHeight}px`"
    >
      <telia-icon name="link" size="sm" class="b2b-linked-basket-item__icon"></telia-icon>
    </div>
    <div ref="hardware">
      <slot name="hardware" id="hardware-slot"></slot>
    </div>
    <div ref="subscription">
      <slot name="subscription"></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, ComputedRef } from "vue";
import { getSlotCoordinates } from "./utils";
import { Coords, SlotCoords } from "./types";

const imageHeight = 50;
const hwPos = ref<Coords>({ x: 0, y: 0 });
const subPos = ref<Coords>({ x: 0, y: 0 });

const hardware = ref<HTMLDivElement | null>(null);
const subscription = ref<HTMLDivElement | null>(null);

const distance: ComputedRef<number> = computed<number>(() =>
  Math.abs(subPos.value.y - hwPos.value.y)
);

onMounted(() => {
  const resizeObserver = new ResizeObserver(() => {
    const { hardware: hw, subscription: sub }: SlotCoords = getSlotCoordinates(
      hardware as any,
      subscription as any
    );
    hwPos.value = { x: hw.x, y: hw.y };
    subPos.value = { x: sub.x, y: sub.y };
  });
  resizeObserver.observe(hardware.value! as any);
  resizeObserver.observe(subscription.value! as any) ;
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.b2b-linked-basket-item {
  position: relative;

  &__connector {
    position: absolute;
    top: calc($telia-spacing-48 + $telia-spacing-16);
    left: $telia-spacing-16;
    color: $telia-gray-400;

    &:before {
      margin-top: $telia-spacing-8;
    }

    &:after {
      margin-bottom: $telia-spacing-8;
    }

    &:before,
    &:after {
      content: "";
      height: calc(50% - $telia-spacing-20);
      width: 1px;
      display: block;
      border-right: 1px solid $telia-gray-400;
      margin-left: $telia-spacing-8;
    }
  }

  &__icon {
    padding-top: $telia-spacing-4;
  }
}
</style>
