import {
  HardwareLine,
  ProductAvailability,
  AccessoryLine,
  BasketLineWithStockStatus,
  SubscriptionLine,
  ProcessLine,
  SwitchboardUserLine,
  SubscriptionWithDetails,
} from "../typings/types";
import {
  GetBasketAddonUI,
  GetBasketHuntingGroupUI,
} from "@telia/b2b-rest-client/dist/corp-product-order";

export function buildLinesWithStockStatus<T extends HardwareLine | AccessoryLine>(
  basketLines: T[] | undefined,
  productStockStatuses: ProductAvailability[],
  fetchingStockStatusError: boolean
): BasketLineWithStockStatus<T>[] {
  return (
    basketLines?.map((basketLine) => {
      const productStockStatus = productStockStatuses.find(
        (e) => e.sapId === basketLine.product.sapId
      ) ?? { status: "UNKNOWN_STOCK", availableFrom: undefined };

      return {
        ...basketLine,
        stockStatus: fetchingStockStatusError
          ? {
              status: "UNKNOWN_STOCK",
              availableFrom: undefined,
            }
          : productStockStatus,
      };
    }) || []
  );
}

export function findRelatedSubscription(
  relationId: string,
  basketLines: SubscriptionLine[] | undefined
): SubscriptionLine | undefined {
  return basketLines?.find((subscription) => relationId && subscription.relationId === relationId);
}

export function findRelatedHardware(
  relationId: string,
  hardwareLines: HardwareLine[] | undefined
): HardwareLine | undefined {
  return hardwareLines?.find((hardware) => relationId && hardware.relationId === relationId);
}

export function findRelatedNumberSwap(
  relationId: string,
  processLines: ProcessLine[] | undefined
): ProcessLine | undefined {
  return processLines?.find((item) => relationId && item.relationId === relationId);
}

export function findRelatedSwitchboardUser(
  relationId: string,
  switchboardUserLines: SwitchboardUserLine[] | undefined
): SwitchboardUserLine | undefined {
  return switchboardUserLines?.find((item) => relationId && item.relationId === relationId);
}

export function mapToSubscriptionWithDetails(
  subscription: SubscriptionLine,
  processLines: ProcessLine[] | undefined,
  switchboardUserLines: SwitchboardUserLine[] | undefined
): SubscriptionWithDetails {
  const numberSwapItem = findRelatedNumberSwap(subscription.relationId, processLines);
  const switchboardUser = findRelatedSwitchboardUser(subscription.relationId, switchboardUserLines);
  return {
    ...subscription,
    numberSwapDetails: numberSwapItem && {
      msisdn: numberSwapItem.currentMsisdn,
      temporaryMsisdn: numberSwapItem.temporaryMsisdn,
      currentOwnerSsn: numberSwapItem.currentOwnerSsn,
      currentOwnerEmail: numberSwapItem.currentOwnerEmail,
      currentOwnerOrganization: numberSwapItem.currentOwnerOrganization,
      desiredDeliveryDate: numberSwapItem.desiredDeliveryDate,
      oneTimeFee: numberSwapItem.oneTimeFee,
      currentOwnerType: numberSwapItem.currentOwnerType,
      isTransfer: numberSwapItem.product.subCategory === "transferofownership",
    },
    switchboardUserDetails: switchboardUser && {
      name: switchboardUser.product.name,
      recurringFee: switchboardUser.product.recurringFee,
      oneTimeFee: switchboardUser.product.oneTimeFee,
      totalRecurringFee: switchboardUser.recurringFee,
      totalOneTimeFee: switchboardUser.oneTimeFee,
      addons: mapToAddons(switchboardUser.addons),
      huntingGroups: mapToHuntingGroups(switchboardUser.product.huntingGroups),
    },
  };
}

export function mapToAddons(addons: GetBasketAddonUI[]) {
  return addons.map((addon) => ({
    name: addon.name,
    oneTimeFee: addon.oneTimeFee,
    recurringFee: addon.recurringFee,
  }));
}

export function mapToHuntingGroups(switchboardHuntingGroups: GetBasketHuntingGroupUI[]) {
  return (
    switchboardHuntingGroups?.map((group) => ({
      id: group.groupNumber,
      name: group.groupNumber,
    })) || []
  );
}
