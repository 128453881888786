import BasketSummary from "./components/basket-summary.ce.vue";
import ProductCart from "./product-cart.ce.vue";
import SubscriptionBasketItem from "../b2b-subscription-basket-item/subscription-basket-item.entry";
import B2bHardwareBasketItem from "../b2b-hardware-basket-item/hardware-basket-item.ce.vue";
import B2bLinkedBasketItem from "../b2b-linked-basket-item/linked-basket-item.ce.vue";
import B2bEmnSubscriptionBasketItem from "../b2b-emn-subscription-basket-item/emn-subscription-basker-item.ce.vue";

const styles = mergeStylesOfComponents(
  ProductCart,
  BasketSummary,
  SubscriptionBasketItem,
  B2bHardwareBasketItem,
  B2bLinkedBasketItem,
  B2bEmnSubscriptionBasketItem
);

ProductCart.styles = styles;

export default ProductCart;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
