<template>
  <accordion-migr
    class="b2b-subscription-basket-item__details-view-accordion"
    t-id="b2b-subscription-basket-item__details-view-accordion"
    :full-width-content="true"
    :open="accordionOpen"
    @change="handleChangeOnAccordion"
  >
    <template #header>
      <telia-heading
        tag="h3"
        variant="title-100"
        class="b2b-subscription-basket-item__accordion-title"
        :class="{ hidden: !accordionOpen }"
      >
        {{ t("CHOICES_HEADING") }}
      </telia-heading>
    </template>
    <template #body>
      <ul
        t-id="b2b-subscription-basket-item__details-view-items"
        class="b2b-subscription-basket-item__details-view-items"
      >
        <li
          t-id="b2b-subscription-basket-item__commitment"
          class="b2b-subscription-basket-item__details-view-item"
        >
          <telia-p
            >{{
              hasCommitmentPeriod
                ? t("COMMITMENT_PERIOD_SHORT", {
                    months: commitmentPeriod,
                  })
                : t("NO_COMMITMENT_PERIOD")
            }}
          </telia-p>
        </li>
        <li
          v-if="!isExtension"
          t-id="b2b-subscription-basket-item__delivery-method"
          class="b2b-subscription-basket-item__details-view-item"
        >
          <telia-p>{{ t("DELIVERY_METHOD_" + deliveryMethod + (isMbb ? "_MBB" : "")) }}</telia-p>
        </li>
        <li
          t-id="b2b-subscription-basket-item__one-time-fee"
          class="b2b-subscription-basket-item__details-view-item"
        >
          <telia-p class="label">{{ t("ONE_TIME_FEE_TITLE") }}</telia-p>
          <telia-p class="value"
            ><span class="b2b-subscription-basket-item__bold">{{
              t("ONE_TIME_FEE_SHORT", { amount: formatCurrency(safeParseToNumber(oneTimeFee)) })
            }}</span></telia-p
          >
        </li>
        <li
          t-id="b2b-subscription-basket-item__addon"
          class="b2b-subscription-basket-item__details-view-item"
          v-for="(addon, index) in addons"
          :key="index"
        >
          <telia-p class="label">
            {{ addon.name }}
          </telia-p>
          <telia-p class="value">
            <span class="b2b-subscription-basket-item__bold">{{
              t("RECURRING_FEE", {
                price: formatCurrency(getFeeAndParseToNumber("recurringFee")(addon)),
              })
            }}</span>
          </telia-p>
        </li>
        <li
          t-id="b2b-subscription-basket-item__increased-fee"
          class="b2b-subscription-basket-item__details-view-item"
          v-if="hasIncreasedFeeDueToMobileDevice"
        >
          <telia-p class="label">
            {{ t("INCREASED_MRC_MOBILE_DEVICE") }}
          </telia-p>
          <telia-p class="value">
            <span class="b2b-subscription-basket-item__bold">{{
              t("RECURRING_FEE", {
                price: formatCurrency(safeParseToNumber(increasedMrcForMobileDevice)),
              })
            }}</span>
          </telia-p>
        </li>
        <li
          t-id="b2b-subscription-basket-item__extra"
          class="b2b-subscription-basket-item__extra b2b-subscription-basket-item__details-view-item"
          v-if="datasims.length"
        >
          <telia-p class="label">
            <span v-if="datasims.length > 1">{{ datasims.length }}x&nbsp;</span>
            {{ datasims[0].name }}
          </telia-p>
          <telia-p class="value">
            <span class="b2b-subscription-basket-item__bold">{{
              t("RECURRING_FEE", {
                price: formatCurrency(totalMonthlyCostForDatasim),
              })
            }}</span>
          </telia-p>
        </li>
        <li
          t-id="b2b-subscription-basket-item__extra"
          class="b2b-subscription-basket-item__extra b2b-subscription-basket-item__details-view-item"
          v-if="mds"
        >
          <telia-p class="label">
            {{ t("MDS") }}
          </telia-p>
          <telia-p class="value">
            <span class="b2b-subscription-basket-item__bold">{{
              t("RECURRING_FEE", { price: formatCurrency(totalMonthlyCostForMDS) })
            }}</span>
          </telia-p>
        </li>
      </ul>
      <div v-if="includesSwitchboardUser">
        <telia-heading
          tag="h3"
          variant="title-100"
          class="b2b-subscription-basket-item__header"
          t-id="b2b-subscription-basket-item__details-view__switchboard-user"
        >
          {{ switchboardUserDetails?.name }}
        </telia-heading>
        <ul class="b2b-subscription-basket-item__details-view-items">
          <li t-id="b2b-subscription-basket-item__details-view__switchboard-user-recurring">
            <telia-p class="label">{{ t("RECURRING_FEE_TITLE") }}</telia-p>
            <telia-p class="value">
              <span class="b2b-subscription-basket-item__bold">{{
                t("RECURRING_FEE", {
                  price: formatCurrency(
                    getFeeAndParseToNumber("recurringFee")(switchboardUserDetails)
                  ),
                })
              }}</span>
            </telia-p>
          </li>
          <li
            v-for="(addon, index) in switchboardUserDetails?.addons ?? []"
            :key="index"
            t-id="b2b-subscription-basket-item__details-view__switchboard-user-addon"
          >
            <telia-p class="label">
              {{ addon.name }}
            </telia-p>
            <telia-p class="value">
              <span class="b2b-subscription-basket-item__bold"
                >{{
                  !isPriceZero(getFeeAndParseToNumber("oneTimeFee")(addon))
                    ? t("ONE_TIME_FEE", {
                        amount: formatCurrency(getFeeAndParseToNumber("oneTimeFee")(addon)),
                      })
                    : ""
                }}
                {{
                  !isPriceZero(getFeeAndParseToNumber("recurringFee")(addon))
                    ? t("RECURRING_FEE", {
                        price: formatCurrency(getFeeAndParseToNumber("recurringFee")(addon)),
                      })
                    : ""
                }}</span
              >
            </telia-p>
          </li>
          <li v-if="includesHuntingGroups">
            <telia-p>
              {{ t("HUNTING_GROUPS") }}
            </telia-p>
            <section
              t-id="b2b-subscription-basket-item__details-view__switchboard-user-hunting-groups"
              class="b2b-subscription-basket-item__details-view__switchboard-user-hunting-groups"
            >
              <telia-p
                t-id="b2b-subscription-basket-item__details-view__switchboard-user-hunting-group"
                v-for="(huntingGroup, index) in huntingGroups"
                :key="huntingGroup.id"
              >
                {{
                  `${huntingGroup.name || huntingGroup.id}${
                    index !== huntingGroups.length - 1 ? "," : ""
                  } `
                }}
              </telia-p>
            </section>
          </li>
        </ul>
      </div>
      <div v-if="includesNumberSwap" t-id="b2b-subscription-basket-item__details-view__number-swap">
        <telia-heading tag="h3" variant="title-100" class="b2b-subscription-basket-item__header">
          {{ t("NUMBER_SWAP_TITLE") }}
        </telia-heading>
        <ul class="b2b-subscription-basket-item__details-view__number-swap">
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__one-time-fee"
            class="b2b-subscription-basket-item__details-view-item"
          >
            <telia-p
              >{{
                t("NUMBER_SWAP_FEE", {
                  amount: formatCurrency(getFeeAndParseToNumber("oneTimeFee")(numberSwapDetails)),
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__msisdn"
            class="b2b-subscription-basket-item__details-view-item"
          >
            <telia-p
              >{{
                t(numberSwapLabel, {
                  msisdn: formatMsisdn(numberSwapDetails?.msisdn),
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__temporary-msisdn"
            class="b2b-subscription-basket-item__details-view-item"
            v-if="!numberSwapDetails?.isTransfer"
          >
            <telia-p
              >{{
                t("NUMBER_SWAP_TEMPORARY", {
                  msisdn: formatMsisdn(numberSwapDetails?.temporaryMsisdn),
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__current-owner-ssn"
            class="b2b-subscription-basket-item__details-view-item"
            v-if="showNumberSwapSsn"
          >
            <telia-p
              >{{
                t("NUMBER_SWAP_CURRENT_OWNER_SSN_OR_ORG", {
                  ssn: formatSsn(numberSwapDetails?.currentOwnerSsn),
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__current-owner-org"
            class="b2b-subscription-basket-item__details-view-item"
            v-if="showNumberSwapOrg"
          >
            <telia-p
              >{{
                t("NUMBER_SWAP_CURRENT_OWNER_SSN_OR_ORG", {
                  ssn: formatOrganizationNo(numberSwapDetails?.currentOwnerOrganization),
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__current-signatory-ssn"
            class="b2b-subscription-basket-item__details-view-item"
            v-if="showNumberSwapSignatory"
          >
            <telia-p
              >{{
                t("NUMBER_SWAP_CURRENT_ORG_SIGNATORY_SSN", {
                  ssn: formatSsn(numberSwapDetails?.currentOwnerSsn),
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__current-owner-email"
            class="b2b-subscription-basket-item__details-view-item"
            v-if="showNumberSwapEmail"
          >
            <telia-p
              >{{
                t("NUMBER_SWAP_CURRENT_OWNER_EMAIL", {
                  email: numberSwapDetails?.currentOwnerEmail ?? "",
                })
              }}
            </telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__details-view__number-swap__date"
            class="b2b-subscription-basket-item__details-view-item"
          >
            <telia-p
              v-if="numberSwapDetails?.desiredDeliveryDate"
              t-id="number-swap-container__date"
              >{{ t("NUMBER_SWAP_DATE", { date: numberSwapDetails.desiredDeliveryDate }) }}</telia-p
            >
          </li>
        </ul>
      </div>
      <div
        t-id="b2b-subscription-basket-item__details-view-total-cost-container"
        v-if="hasCommitmentPeriod"
      >
        <telia-heading tag="h3" variant="title-100" class="b2b-subscription-basket-item__header">
          {{ t("TOTAL_COST_HEADING") }}
        </telia-heading>
        <ul class="b2b-subscription-basket-item__details-view-total-cost">
          <li
            t-id="b2b-subscription-basket-item__total-price"
            class="b2b-subscription-basket-item__total-price"
          >
            <telia-p
              >{{
                t("TOTAL_COST_FOR_COMMITMENT_PERIOD", {
                  amount: formatCurrency(totalCostExcludingAddonsForCommitmentPeriod),
                })
              }}
            </telia-p>
          </li>
          <li>
            <telia-p>{{ t("VARIABLE_COST_CONDITIONS") }}</telia-p>
          </li>
          <li
            v-if="isExtension"
            t-id="b2b-subscription-basket-item__extended-addon-cost-not-included"
          >
            <telia-p>{{ t("ADDON_COST_ON_EXTENSION") }}</telia-p>
          </li>
          <li
            t-id="b2b-subscription-basket-item__addons-total-price"
            class="b2b-subscription-basket-item__addons-price"
            v-if="!isExtension"
          >
            <telia-p
              >{{
                t("TOTAL_COST_OF_ADDONS_FOR_COMMITMENT_PERIOD", {
                  amount: formatCurrency(totalCostOfAddonsForCommitmentPeriod),
                })
              }}
            </telia-p>
          </li>
        </ul>
      </div>
    </template>
  </accordion-migr>
</template>

<script setup lang="ts">
import { computed, ComputedRef, ref, Ref } from "vue";
import AccordionMigr from "./AccordionMigr.ce.vue";
import { currentLanguage } from "../../utils/language";
import { translateMixin, translateSetup } from "../locale";
import FormatCurrency, { sum, sumAll } from "../../utils/currency";
import { formatSsn, formatOrganizationNo } from "../../utils/formatters";
import formatMsisdn from "../../utils/phoneNumber";
import { getFeeAndParseToNumber, safeParseToNumber } from "../../utils/fp";
import { Lang, OwnerType } from "../../typings/types";
import {
  MappedAddon,
  DataSim,
  Mds,
  DeliveryMethod,
  NumberSwapDetails,
  SwitchboardUserDetails,
  MappedHuntingGroup,
} from "../../typings/types";

translateSetup();
const pageLanguage: Lang = currentLanguage() as Lang;

const formatCurrency = FormatCurrency(pageLanguage);
const t = translateMixin.methods.t;

interface Props {
  addons: MappedAddon[];
  commitmentPeriod: number;
  isExtension: boolean;
  datasims: DataSim[];
  mds?: Mds;
  recurringFee?: string;
  oneTimeFee?: string;
  startUpFee?: string;
  deliveryMethod?: DeliveryMethod;
  isMbb: boolean;
  increasedMrcForMobileDevice: string;
  numberSwapDetails?: NumberSwapDetails;
  switchboardUserDetails?: SwitchboardUserDetails;
}

const props = defineProps<Props>();
const accordionOpen: Ref<boolean> = ref<boolean>(false);

const totalMonthlyCostForDatasim: ComputedRef<number> = computed<number>(() => {
  return props.datasims.map(getFeeAndParseToNumber("recurringFee")).reduce(sum, 0);
});
const totalMonthlyCostForMDS: ComputedRef<number> = computed<number>(() => {
  return getFeeAndParseToNumber("recurringFee")(props.mds);
});
const totalCostOfAddonsForCommitmentPeriod: ComputedRef<number> = computed<number>(() => {
  const fee = props.addons.map(getFeeAndParseToNumber("recurringFee")).reduce(sum, 0);
  return (
    sumAll(fee, totalMonthlyCostForDatasim.value, totalMonthlyCostForMDS.value) *
    props.commitmentPeriod
  );
});
const totalCostExcludingAddonsForCommitmentPeriod: ComputedRef<number> = computed<number>(() => {
  return (
    getFeeAndParseToNumber("recurringFee")(props) * props.commitmentPeriod -
    totalCostOfAddonsForCommitmentPeriod.value +
    getFeeAndParseToNumber("oneTimeFee")(props)
  );
});
const hasCommitmentPeriod: ComputedRef<boolean> = computed<boolean>(() => {
  return !!props.commitmentPeriod;
});
const hasIncreasedFeeDueToMobileDevice: ComputedRef<boolean> = computed<boolean>(() => {
  return getFeeAndParseToNumber("increasedMrcForMobileDevice")(props) >= 0;
});
const includesNumberSwap: ComputedRef<boolean> = computed<boolean>(() => {
  return !!props.numberSwapDetails;
});
const includesSwitchboardUser: ComputedRef<boolean> = computed<boolean>(() => {
  return !!props.switchboardUserDetails;
});
const includesHuntingGroups: ComputedRef<boolean> = computed<boolean>(() => {
  return (
    includesSwitchboardUser.value && (props.switchboardUserDetails?.huntingGroups?.length ?? -1) > 0
  );
});
const isNumberSwapOrganization: ComputedRef<boolean> = computed<boolean>(() => {
  return props.numberSwapDetails?.currentOwnerType === OwnerType.ORGANIZATION;
});
const showNumberSwapOrg: ComputedRef<boolean> = computed<boolean>(() => {
  return isNumberSwapOrganization.value && !props.numberSwapDetails?.isTransfer;
});
const showNumberSwapSsn: ComputedRef<boolean> = computed<boolean>(() => {
  return isNumberSwapPrivate.value && !props.numberSwapDetails?.isTransfer;
});

const isNumberSwapPrivate: ComputedRef<boolean> = computed<boolean>(() => {
  return props.numberSwapDetails?.currentOwnerType === OwnerType.PRIVATE;
});
const showNumberSwapSignatory: ComputedRef<boolean> = computed<boolean>(() => {
  return (
    !!props.numberSwapDetails &&
    !!props.numberSwapDetails.currentOwnerSsn &&
    !!isNumberSwapOrganization.value
  );
});
const showNumberSwapEmail: ComputedRef<boolean> = computed<boolean>(() => {
  return !!props.numberSwapDetails && !!props.numberSwapDetails.currentOwnerEmail;
});
const numberSwapLabel: ComputedRef<string> = computed<string>(() => {
  return props.numberSwapDetails?.isTransfer ? "NUMBER_SWAP_TRANSFER_OF" : "NUMBER_SWAP_PORTING_OF";
});
const huntingGroups: ComputedRef<MappedHuntingGroup[]> = computed<MappedHuntingGroup[]>(() => {
  return props.switchboardUserDetails?.huntingGroups ?? [];
});

function handleChangeOnAccordion(e): void {
  accordionOpen.value = e;
}

function isPriceZero(price: number): boolean {
  return price === 0;
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/typography/variables";

.b2b-subscription-basket-item {
  &__bold {
    font-weight: $telia-typography-weight-bold;
  }

  &__header {
    margin-bottom: $telia-spacing-12;
  }

  &__details-view {
    &-accordion {
      border-bottom: 0;
      margin-left: calc(5rem + $telia-spacing-12);

      .b2b-subscription-basket-item__accordion-title {
        transition: 200ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

        &.hidden {
          opacity: 0;
        }
      }
    }

    &-items,
    &-total-cost,
    &__number-swap,
    &__switchboard-user {
      margin: 0 0 $telia-spacing-32 0;
      padding: 0;
      list-style-type: none;

      & :first-child {
        margin-top: 0;
      }

      & > li {
        margin: $telia-spacing-4 0;
        line-height: 2rem;
        display: flex;

        & .label {
          flex: 0 0 50%;
        }

        & .value {
          flex-grow: 1;
          font-weight: bold;
          text-align: right;
        }
      }

      &-hunting-groups {
        margin-left: $telia-spacing-4;
        display: flex;
        flex-wrap: wrap;
      }

      &-hunting-groups > span {
        margin-right: $telia-spacing-4;
      }
    }

    &-price-cost {
      margin: $telia-spacing-4 0;
    }

    &-addon-cost {
      margin: $telia-spacing-4 0;
    }
  }

  &__extra {
    display: flex;
    align-items: center;

    &-icon {
      margin-left: 2px;
    }
  }
}
</style>
