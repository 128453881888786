import { AgreementType, Product, ga4tracking } from "@telia/b2b-ecommerce-tracking";
import { AnyBasketLineType, Basket, Line, LineType } from "../typings/types";
import Big from "big.js";
import { buildLinePayload, getAllBasketLines, totalProductPrice } from "./tracking/ga4Util";

function boughtWithHardware(basket: Basket, relationId: string) {
  return basket.hardwareLines.some((hardwareLine) => hardwareLine.relationId === relationId);
}

export function trackCheckout(basket: Basket, isReplicatedMfaCustomer: boolean = false) {
  const products = getAllBasketLines(basket)
    .map((line) => buildLinePayload(line, boughtWithHardware(basket, line.relationId)))
    .flat();

  ga4tracking.trackBeginCheckout({
    pageSegment: "mybusiness",
    isLoggedIn: true,
    listName: "",
    stepLabel: "start",
    stepNumber: 1,
    products: products,
    agreementType: isReplicatedMfaCustomer ? AgreementType.MFA : AgreementType.NFA,
    totalPrice: totalProductPrice(products),
  });
}

export function trackRemoveItem(
  removedBasketLine: AnyBasketLineType<Line>,
  linkedBasketLines: AnyBasketLineType<Line>[],
  isReplicatedMfaCustomer: boolean = false
) {
  const boughtWithHardware = [removedBasketLine, ...linkedBasketLines].some(
    (line) => line.product.category === "goods"
  );
  const products = [removedBasketLine, ...linkedBasketLines]
    .map((line) => buildLinePayload(line, boughtWithHardware))
    .flat();

  ga4tracking.trackRemoveFromCart({
    isLoggedIn: true,
    agreementType: isReplicatedMfaCustomer ? AgreementType.MFA : AgreementType.NFA,
    listName: "BasketItemList",
    totalPrice: totalProductPrice(products),
    products,
    pageSegment: "mybusiness",
  });
}

export function trackViewCart(basket: Basket, isReplicatedMfaCustomer: boolean = false) {
  const products = getAllBasketLines(basket)
    .map((line) => buildLinePayload(line, boughtWithHardware(basket, line.relationId)))
    .flat();
  ga4tracking.trackViewCart({
    pageSegment: "mybusiness",
    isLoggedIn: true,
    listName: "",
    products: products,
    agreementType: isReplicatedMfaCustomer ? AgreementType.MFA : AgreementType.NFA,
    totalPrice: totalProductPrice(products),
  });
}
