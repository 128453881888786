<template>
  <div t-id="b2b-subscription-basket-item" class="b2b-subscription-basket-item">
    <div class="b2b-subscription-basket-item-container">
      <img
        t-id="b2b-subscription-basket-item-image"
        class="b2b-subscription-basket-item__subscription-image"
        :src="simcard"
      />
      <div
        class="b2b-subscription-basket-item__content-container"
        :class="{ 'b2b-subscription-basket-item__margin-bottom': hasPrice }"
      >
        <telia-heading
          t-id="b2b-subscription-basket-item__name"
          class="b2b-subscription-basket-item__name"
          tag="h2"
          :variant="smallLayout ? 'subsection-100' : 'title-200'"
          :class="{ 'b2b-subscription-basket-item__name--small': smallLayout }"
        >
          {{ name }}
        </telia-heading>

        <telia-p t-id="b2b-subscription-basket-item__subtitle">
          {{ subtitle }}
        </telia-p>
        <div class="b2b-subscription-basket-item__info-line">
          <telia-p
            t-id="b2b-subscription-basket-item__username"
            class="b2b-subscription-basket-item__username"
          >
            {{ userName }}
          </telia-p>
          <div class="b2b-subscription-basket-item__phonenumber-container">
            <telia-p>
              <span
                t-id="b2b-subscription-basket-item__phonenumber"
                :class="{
                  'b2b-subscription-basket-item__phonenumber--invalid': invalidMsisdn,
                }"
              >
                {{ formatMsisdn(msisdn) }}
              </span>
            </telia-p>
            <telia-icon
              v-if="invalidMsisdn"
              t-id="b2b-subscription-basket-item__msisdn-invalid-icon"
              class="b2b-subscription-basket-item__msisdn-invalid-icon"
              name="error-filled"
              size="md"
            ></telia-icon>
          </div>
        </div>
        <telia-p v-if="switchboardUserDetails">
          <span
            t-id="b2b-subscription-basket-item__switchboard-user-product"
            class="b2b-subscription-basket-item__switcboard-user-product"
            >{{ switchboardUserDetails.name }}</span
          >
        </telia-p>
      </div>
      <telia-button
        class="b2b-subscription-basket-item__select-button"
        v-if="showSelectButton"
        variant="primary"
        size="sm"
        @click.prevent="onSelectButtonClicked"
      >
        {{ t("SELECT") }}
      </telia-button>
    </div>
    <div>
      <telia-p
        v-if="hasPrice"
        t-id="b2b-subscription-basket-item-pricing"
        class="b2b-subscription-basket-item-pricing"
        v-html="recurringFeePrint"
      />
    </div>
    <number-swap-card
      v-if="numberSwapDetails"
      :details="numberSwapDetails"
      :small-layout="smallLayout"
    />
    <details-view
      v-if="!hideDetails"
      :datasims="datasims"
      :mds="mds"
      :commitment-period="commitmentPeriod"
      :is-extension="operation === Operation.EXTEND_SUBSCRIPTION"
      :recurring-fee="recurringFee"
      :one-time-fee="oneTimeFee"
      :delivery-method="deliveryMethod"
      :is-mbb="isMbb"
      :addons="addons"
      :increased-mrc-for-mobile-device="increasedMrcForMobileDevice"
      :number-swap-details="numberSwapDetails"
      :switchboard-user-details="switchboardUserDetails"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ComputedRef, PropType } from "vue";
import {
  Operation,
  MappedAddon,
  DeliveryMethod,
  DataSim,
  Mds,
  NumberSwapDetails,
  SwitchboardUserDetails,
  Lang,
} from "../typings/types";
import simcard from "./simcard.png";
import { translateMixin, translateSetup } from "./locale";
import DetailsView from "./components/DetailsView.ce.vue";
import formatMsisdn from "../utils/phoneNumber";
import NumberSwapCard from "./components/NumberSwapCard.ce.vue";
import { currentLanguage } from "../utils/language";
import FormatCurrency from "../utils/currency";
import { getFeeAndParseToNumber } from "../utils/fp";

const pageLanguage: Lang = currentLanguage() as Lang;
const formatCurrency = FormatCurrency(pageLanguage);

const props = defineProps({
  name: String,
  datasims: {
    type: Array as PropType<DataSim[]>,
    default: () => [],
  },
  mds: {
    type: Object as PropType<Mds>,
    default: () => null,
  },
  commitmentPeriod: {
    type: Number,
    default: 0,
  },
  operation: {
    type: String as PropType<Operation>,
    default: Operation.NEW_SUBSCRIPTION,
  },
  userName: {
    type: String,
    required: true,
  },
  msisdn: {
    type: String,
    required: true,
  },
  smallLayout: {
    type: Boolean,
    default: false,
  },
  recurringFee: String,
  oneTimeFee: String,
  addons: {
    type: Array as PropType<MappedAddon[]>,
    default: () => [],
  },
  deliveryMethod: {
    type: String as PropType<DeliveryMethod>,
  },
  increasedMrcForMobileDevice: {
    type: String,
    default: "",
  },
  invalidMsisdn: {
    type: Boolean,
    default: false,
  },
  hideDetails: { type: Boolean, default: false },
  numberSwapDetails: {
    type: Object as PropType<NumberSwapDetails>,
  },
  switchboardUserDetails: { type: Object as PropType<SwitchboardUserDetails> },
  showSelectButton: { type: Boolean, default: false },
});

const emit = defineEmits<{
  (e: "selected"): void;
}>();
translateSetup();

const t = translateMixin.methods.t;

const subtitle: ComputedRef<string> = computed<string>(() => {
  const operationText =
    props.operation === Operation.EXTEND_SUBSCRIPTION
      ? t(Operation.EXTEND_SUBSCRIPTION)
      : t(Operation.NEW_SUBSCRIPTION);

  if (!props.commitmentPeriod) {
    return operationText;
  }

  return `${operationText} - ${t("COMMITMENT_PERIOD", {
    months: props.commitmentPeriod,
  })}`;
});

const isMbb: ComputedRef<boolean> = computed<boolean>(() => props.msisdn?.length > 11);
const hasOneTimeFee: ComputedRef<boolean> = computed<boolean>(
  () => !!props.oneTimeFee && props.oneTimeFee !== "0"
);
const hasRecurringFee: ComputedRef<boolean> = computed<boolean>(
  () => !!props.recurringFee && props.recurringFee !== "0"
);
const hasPrice: ComputedRef<boolean> = computed<boolean>(
  () => hasOneTimeFee.value || hasRecurringFee.value
);
const recurringFeePrint: ComputedRef<string> = computed<string>(() => {
  const totalRecurringFee =
    getFeeAndParseToNumber("recurringFee")(props) +
    getFeeAndParseToNumber("totalRecurringFee")(props.switchboardUserDetails);

  const fee = formatCurrency(totalRecurringFee);
  return pageLanguage === Lang.EN ? `SEK <b>${fee}</b>/month` : `<b>${fee}</b> kr/mån`;
});

function onSelectButtonClicked() {
  emit("selected");
}
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.b2b-subscription-basket-item {
  padding: $telia-spacing-12 0;
}

.b2b-subscription-basket-item-container {
  display: flex;
  gap: $telia-spacing-12;
}

.b2b-subscription-basket-item__content-container {
  flex: 1 1 auto;
}

.b2b-subscription-basket-item__subscription-image {
  width: 50px;
  align-self: flex-start;
  margin-top: 2px;
}

.b2b-subscription-basket-item__name {
  padding-right: $telia-spacing-24;
}

.b2b-subscription-basket-item__name--small {
  margin-bottom: $telia-spacing-4;
}

.b2b-subscription-basket-item__margin-bottom {
  margin-bottom: $telia-spacing-4;
}

.b2b-subscription-basket-item__info-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $telia-spacing-8;
}

.b2b-subscription-basket-item__phonenumber-container {
  display: inline-flex;
  align-items: center;
  gap: $telia-spacing-4;
  flex: 1 1 auto;
}

.b2b-subscription-basket-item__phonenumber--invalid {
  color: $telia-orange-500;
  font-weight: bold;
}

b2b-subscription-basket-item__username {
  flex: 1 1 auto;
}

.b2b-subscription-basket-item-pricing {
  display: flex;
  justify-content: flex-end;
}

.b2b-subscription-basket-item__msisdn-invalid-icon {
  color: $telia-orange-500;
  height: $telia-spacing-24;
}

.b2b-subscription-basket-item__switcboard-user-product {
  font-weight: bold;
}
</style>
