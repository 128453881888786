import BasketWrapper from "./b2b-basket-wrapper.ce.vue";
import ProductCart from "./components/b2b-product-cart/product-cart.entry";

const styles = mergeStylesOfComponents(BasketWrapper, ProductCart);

BasketWrapper.styles = styles;

export default BasketWrapper;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
