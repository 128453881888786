import NumberSwapCardCe from "./components/NumberSwapCard.ce.vue";
import DetailsViewCe from "./components/DetailsView.ce.vue";
import AccordionMigrCe from "./components/AccordionMigr.ce.vue";
import SubscriptionBasketItemCe from "./subscription-basket-item.ce.vue";

const styles = mergeStylesOfComponents(
  SubscriptionBasketItemCe,
  NumberSwapCardCe,
  DetailsViewCe,
  AccordionMigrCe
);

SubscriptionBasketItemCe.styles = styles;

export default SubscriptionBasketItemCe;

function mergeStylesOfComponents(...components): string[] {
  return components.flatMap(({ styles }) => styles);
}
