import { Ref } from "vue";
import { SlotCoords } from "./types";

export function getSlotCoordinates(
  hardware: Ref<HTMLDivElement | null>,
  subscription: Ref<HTMLDivElement | null>
): SlotCoords {
  const hw: DOMRect = hardware.value!.getBoundingClientRect();
  const sub: DOMRect = subscription.value!.getBoundingClientRect();
  return {
    hardware: { x: hw.x, y: hw.y },
    subscription: { x: sub.x, y: sub.y },
  };
}
