<template>
  <div class="b2b-emn-subscription-item">
    <div class="b2b-emn-subscription-item-container">
      <div
        class="b2b-emn-subscription-item__content-container"
        :class="{ 'b2b-emn-subscription-item__margin-bottom': hasPrice }"
      >
        <telia-heading class="b2b-emn-subscription-item__name" tag="h2" variant="title-200">
          {{ name }}
        </telia-heading>

        <div class="b2b-emn-subscription-item__info-line">
          <telia-p v-if="site">
            {{ t("emn.site", { site }) }}
          </telia-p>
        </div>
        <div class="b2b-emn-subscription-item__info-line">
          <telia-p>
            {{ t("emn.quantity", { quantity }) }}
          </telia-p>
        </div>
        <div v-if="ipRangeStart" class="b2b-emn-subscription-item__info-line">
          <telia-p>
            {{ t("emn.ipRangeStart", { ipRangeStart }) }}
          </telia-p>
        </div>
      </div>
    </div>
    <div>
      <telia-p
        v-if="hasPrice"
        t-id="b2b-emn-subscription-item-pricing"
        class="b2b-emn-subscription-item-pricing"
        v-html="recurringFeePrint"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from "vue";
import { translateMixin, translateSetup } from "../../locale";
import { currentLanguage } from "@telia/b2b-i18n";
import { getFeeAndParseToNumber } from "../utils/fp";
import { Lang } from "../typings/types";
import FormatCurrency from "../utils/currency";

const pageLanguage: Lang = currentLanguage() as Lang;
const formatCurrency = FormatCurrency(pageLanguage);

translateSetup();
const t = translateMixin.methods.t;

interface Props {
  name: string;
  quantity: number;
  ipRangeStart: string;
  site: string;
  oneTimeFee: string;
  recurringFee: string;
  totalOneTimeFee: string;
  totalRecurringFee: string;
}

const props = defineProps<Props>();
const hasPrice = computed(() => hasOneTimeFee.value || hasRecurringFee.value);
const hasOneTimeFee = computed(() => {
  return props.oneTimeFee && Number(props.oneTimeFee) > 0;
});

const hasRecurringFee = computed(() => {
  return props.recurringFee && Number(props.recurringFee) > 0;
});

const recurringFeePrint: ComputedRef<string> = computed<string>(() => {
  const totalRecurringFee =
    getFeeAndParseToNumber("totalRecurringFee")(props);

  const fee = formatCurrency(totalRecurringFee);
  return pageLanguage === Lang.EN ? `SEK <b>${fee}</b>/month` : `<b>${fee}</b> kr/mån`;
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.b2b-emn-subscription-item {
  padding: $telia-spacing-12 0;
}

.b2b-emn-subscription-item-container {
  display: flex;
  gap: $telia-spacing-12;
}

.b2b-emn-subscription-item__content-container {
  flex: 1 1 auto;
}

.b2b-emn-subscription-item__name {
  padding-right: $telia-spacing-24;
}

.b2b-emn-subscription-item__name--small {
  margin-bottom: $telia-spacing-4;
}

.b2b-emn-subscription-item__margin-bottom {
  margin-bottom: $telia-spacing-4;
}

.b2b-emn-subscription-item__info-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $telia-spacing-8;
}

.b2b-emn-subscription-item-pricing {
  display: flex;
  justify-content: flex-end;
}
</style>
