<template>
  <div
    t-id="b2b-accordion-migr"
    class="b2b-accordion-migr"
    :class="{
      'b2b-accordion-migr--opened': isOpen && openBackground,
      'b2b-accordion-migr--border-top': isOpen && borderTop,
    }"
  >
    <div
      class="accordion-tabs"
      :class="{ 'accordion-tabs--show-overflow': showOverflow && isOpen }"
    >
      <div
        class="accordion-tab"
        :class="{ 'accordion-tab--show-overflow': showOverflow && isOpen }"
      >
        <input type="checkbox" :id="uniqueId" v-model="isOpen" :disabled="isDisabled" />
        <label
          class="accordion-tab-label"
          :class="{
            'accordion-tab-label--narrow': narrow,
            'accordion-tab-label--disabled': disabled,
            'accordion-tab-label--has-subheader': hasSubheader,
            'accordion-tab-label--margin-top': extraTopMargin,
            'accordion-tab-label--margin-top-opened': extraTopMargin && isOpen,
          }"
          :for="uniqueId"
        >
          <div class="accordion-tab-title" ref="accordionTabTitle">
            <div class="accordion-tab-preheader">
              <slot name="preheader"></slot>
            </div>
            <div class="accordion-tab-header-wrapper">
              <div class="accordion-tab-header">
                <slot name="header"></slot>
              </div>
              <div
                class="accordion-tab-subheader"
                :class="{ 'accordion-tab-subheader--open': isOpen }"
              >
                <slot name="subheader"></slot>
              </div>
            </div>
          </div>
          <telia-icon
            class="chevron"
            t-id="b2b-acordion__chevron"
            :class="{
              'chevron--absolute': hasSubheader,
            }"
            name="chevron-down"
            v-if="showCaret"
            size="md"
          ></telia-icon>
        </label>
        <div
          class="accordion-tab-content"
          :class="{
            'accordion-tab-content--tooltip': tooltipSpace,
            'accordion-tab-content--right-indent': !fullWidthContent,
          }"
        >
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ComputedRef, WritableComputedRef, useSlots } from "vue";
import { v4 as uuidv4 } from "uuid";

const uuid = uuidv4();

interface Props {
  id?: string;
  open: boolean;
  narrow?: boolean;
  openBackground?: boolean;
  showCaret?: boolean;
  disabled?: boolean;
  showOverflow?: boolean;
  tooltipSpace?: boolean;
  borderTop?: boolean;
  extraTopMargin?: boolean;
  fullWidthContent?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
  narrow: false,
  openBackground: true,
  showCaret: true,
  disabled: false,
  showOverflow: false,
  tooltipSpace: false,
  borderTop: false,
  extraTopMargin: false,
  fullWidthContent: false,
});
const emit = defineEmits<{ (e: "change", isOpen: boolean): void }>();
const slots = useSlots();
const uniqueId: ComputedRef<string> = computed<string>(() =>
  props.id ? props.id : `b2b-accordion-migr-${uuid}`
);
const isDisabled: ComputedRef<boolean> = computed<boolean>(
  () => props.disabled || !props.showCaret
);
const isOpen: WritableComputedRef<boolean> = computed<boolean>({
  get() {
    return props.open && !isDisabled;
  },
  set(open) {
    emit("change", open);
  },
});
const hasSubheader: ComputedRef<boolean> = computed<boolean>(() => !!slots.subheader);
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.b2b-accordion-migr {
  transition: background-color 0.2s, margin 0.1s, padding 0.1s;

  &--opened {
    margin: 0 calc($telia-spacing-12 * -1);
    padding: 0 $telia-spacing-12;
    background-color: $telia-gray-200;
  }

  &--border-top {
    border-top: 0.1rem solid $telia-gray-300;
    margin-top: -0.1rem;
  }
}

/* Accordion styles */
.accordion-tabs {
  overflow: hidden;

  &--show-overflow {
    overflow: visible;
  }
}

.accordion-tab {
  width: 100%;
  overflow: hidden;

  &--show-overflow {
    overflow: visible;
  }

  &-label {
    display: flex;
    justify-content: space-between;
    padding: $telia-spacing-12 0;
    align-items: center;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }

    &--narrow {
      padding: 0;
    }

    &--margin-top {
      margin: $telia-spacing-4 0;
    }

    &--margin-top-opened {
      margin-top: $telia-spacing-12;
      margin-bottom: 0;
    }

    &--has-subheader {
      position: relative;
    }

    .chevron {
      margin-left: $telia-spacing-12;
      text-align: center;
      transition: transform 0.3s ease;

      &--absolute {
        position: absolute;
        right: 0;
      }
    }
  }

  &-title {
    flex: 1 1;
    display: flex;
  }

  &-header-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &-content {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: inherit;

    &--tooltip {
      max-width: 100%;
      padding-right: 0;
    }

    &--right-indent {
      max-width: calc(100% - $telia-spacing-12 - 3rem);
      padding-right: $telia-spacing-12;
    }
  }

  &-subheader {
    transition: opacity 0.1s, transform 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    transform: translateZ(0);

    &--open {
      opacity: 0;
      transform: translateY($telia-spacing-12) translateZ(0);
    }
  }
}

// :checked
input:checked {
  + .accordion-tab-label {
    .chevron {
      transform: rotateX(180deg);
    }
  }

  ~ .accordion-tab-content {
    max-height: 160vh;
    padding-bottom: $telia-spacing-12;
    opacity: 1;
    visibility: visible;
  }
}
</style>
